/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    h4: "h4",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "While a box cake mix and canned frosting is a great place to start, a homemade cake and frosting offers much more flexibility for taste and appearance. I am by no means a baking expert but it would be inappropriate to post cakes without something resembling a recipe, so here are a few things I've learned from the cakes I've made."), "\n", React.createElement(_components.h2, null, "The Cake"), "\n", React.createElement(_components.h4, null, "Recipes"), "\n", React.createElement(_components.p, null, "For the cake base, I almost always use ", React.createElement(_components.a, {
    href: "https://addapinch.com/the-best-chocolate-cake-recipe-ever/"
  }, "this"), " chocolate cake recipe from ", React.createElement(_components.em, null, React.createElement(_components.a, {
    href: "https://addapinch.com/"
  }, "add a pinch")), "."), "\n", React.createElement(_components.p, null, "Another recipe I like is ", React.createElement(_components.a, {
    href: "https://cakebycourtney.com/apple-cider-donut-bundt-cake/"
  }, "this"), " apple cider donut cake from ", React.createElement(_components.em, null, React.createElement(_components.a, {
    href: "https://cakebycourtney.com/"
  }, "cake by courtney")), "."), "\n", React.createElement(_components.p, null, "I've tried other recipes, but most have been very dry in comparison."), "\n", React.createElement(_components.h4, null, "Shaping"), "\n", React.createElement(_components.p, null, "To flatten the cake, I cut off the rounded dome with a cake leveler or knife. It is also possible to flatten the cake by pressing down on the dome while it is warm, but I find that this creates a dense layer that harms the texture of the cake."), "\n", React.createElement(_components.h2, null, "The Frosting"), "\n", React.createElement(_components.p, null, "There are about a million different kinds of frosting, and I have only tried a few."), "\n", React.createElement(_components.p, null, "American Buttercream is one of the easiest frostings to make, but my family tends to find that a plain or vanilla buttercream it is too sweet to be used for frosting the whole cake, although it can be useful for decorating. A chocolate buttercream is much better, but it can't be easily dyed to a desired color. Cream cheese frosting is also a great option."), "\n", React.createElement(_components.h2, null, "Decorating"), "\n", React.createElement(_components.p, null, "I always do decoration with frosting. I never use fondant. If I wanted the cake to look perfect, I would make it out of modeling clay or plastic."), "\n", React.createElement(_components.p, null, "Most of my decoration is done using stencils. See my guide on cake stenciling ", React.createElement(_components.a, {
    href: "../../../articles/cake-stencil-guide/"
  }, "here"), " for an in-depth walkthrough."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
